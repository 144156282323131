import React from 'react';
import './AdminProductItem.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import roundToTwo from '../../../helpers/roundToTwo';

const AdminProductItem = ({ product, selected, onProductClick, authenticityToken}) => {
  const {info, name, price, urls, id} = product;

  const onDeleteClick = () => {
    if (window.confirm(`Produkt ${name} wirklich löschen?`)) {
      fetch(`products/${id}`, {
        method: 'DELETE',
        headers: {
          'X-CSRF-Token': authenticityToken,
          'Content-Type': 'application/json'
        }
      }).then(res => {
        if (res.status === 200) {
          res.json().then(jsonRes => {
            if (jsonRes.status === 201) {
              window.open(`/admins?active=Produkte`, '_self')
            }
            if (jsonRes.status === 400) {
              window.alert('Etwas ist schief gelaufen.')
            }
          });
        } else {
          window.alert('Etwas ist schief gelaufen.')
        };
      });
    }
  };

  return (
    <div className="product" style={{ 'alignSelf': info ? 'flex-end' : 'flex-start' }}>
      <img className="product-img" src={urls[0]} alt="Bild" />
      <div className="product-bottom">
        <div className="product-text">
          <div>
            {name}
          </div>
          <div>
            {`€${roundToTwo(price / 100)}`}
          </div>
          {info && <div className="product-text__info">{info}</div>}
        </div>
        <div className="product-actions">
          <FontAwesomeIcon
            className="admin-table-actions-icon"
            icon={faEdit}
            onClick={() => window.open(`products/${id}/edit`, '_self')}
          />
          <FontAwesomeIcon
            className="admin-table-actions-icon"
            icon={faTrash}
            onClick={() => onDeleteClick(product)}
          />
        </div>
      </div>
    </div>
  );
}

export default AdminProductItem;