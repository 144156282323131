import React from 'react';
import guk15Img from '../../../assets/images/guk15';
import './Spinner.css';

const Spinner = ({ imgSrc, size, useImg, isFloating, isPulse }) => {
  let width;
  let height;
  let border;
  let borderTop;
  let borderRight;

  switch (size) {
    case 'button':
      width = '20px';
      height = '20px';
      border = '3px solid transparent';
      borderTop = '3px solid #FF4F50';
      borderRight = '3px solid #FF4F50';
      break;
    case 'table':
      height = '54px';
      width = '54px';
      break;
    case 'small':
      width = '20px';
      height = '20px';
      break;
    case 'medium':
      width = '70px';
      height = '70px';
      break;
    case 'large':
      width = '150px';
      height = '150px';
      break;
    default:
      width = '150px';
      height = '150px';
      break;
  }

  if (useImg) {
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', ...(isFloating && styles.floating) }}>
        <img style={{ width, animation: `spin 3s linear infinite` }} src={imgSrc || guk15Img} alt="img" />
      </div>
    );
  }

  if (isPulse) {
    return <div className="spinner-pulse" style={{ ...(isFloating && styles.floating), width, height }}></div>;
  }

  return (
    <div className="spinner-container" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', ...(isFloating && styles.floating) }}>
      <div className="spinner" style={{ width, height, border, borderTop, borderRight }}></div>
    </div>
  );
}

const styles = {
  floating: {
    position: 'absolute', 
    zIndex: 10,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  }
}

export default Spinner;