import React, {useRef} from 'react';
import {useOnClickOutside} from '../hooks';
import './KindergartenList.css'
import listImg from '../../../assets/images/list.png'
import Spinner from './Spinner';

const KindergartenList = (props) => {
  const list = useRef();

  useOnClickOutside(list, () => {
    if (props.showList && props.flooredWidth < 1000) {
      props.onIconClick();
    };
  })

  const onHover = (kindergarten) => {
    props.onItemHover(kindergarten);
  }

  const onMouseLeave = (kindergarten) => {
    props.onItemLeave(kindergarten);
  }

  const getContent = () => {
    if (props.loading) {
      return (
        <Spinner />
      );
    }

    const length = props.kindergartens.length;

    if (!props.loading) {
      return (
        <div className="kindergartenlist-wraper">
          <div className="kindergartenlist-header">
            {length} {length === 1 ? 'Einrichtung' : 'Einrichtungen'} {props.allKitas ? 'deutschlandweit' : 'in der Umgebung'}
          </div>
          {props.kindergartens.map((kindergarten, key) => {
            return (
              <div
                className="kindergartenlist-item"
                id={`kindergartenlist-item-id:${kindergarten.id}`}
                onMouseEnter={() => onHover(kindergarten)}
                onMouseLeave={() => onMouseLeave(kindergarten)}
                onClick={() => props.onItemClick(kindergarten)}
                key={key}
              >
                {kindergarten.name}
              </div>
            );
          })}
        
          <div className="marker-button button-floating" onClick={props.onNewKindergratenClick}> Einrichtung hinzufügen </div>
        </div>
      );
    }
  }

  return (
    <div className="kindergartenlist" ref={list}>
      {props.flooredWidth < 1000 &&
      <div className="kindergartenlist__nav">
        <img 
          className="kindergartenlist__icon" 
          src={listImg} 
          onClick={() => props.onIconClick()} 
        />
        {
          !props.allKitas && 
          <div className="kindergartenlist__nav-number">
            {props.loading && <Spinner size='small'/>}
            {!props.loading && props.kindergartens.length}
          </div>
        }
      </div>
      }
      {props.showList && getContent()}
    </div>
  );
};

export default KindergartenList;