import React, { useState, useEffect } from 'react';
import './AdminKindergartens.css';
import Spinner from '../Spinner';
import AdminKindergartenItem from './AdminKindergartenItem';
import { useFetch } from '../../hooks';
import sorting from '../../../helpers/sorting';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';

const AdminKindergartens = (props) => {
  const { authenticityToken } = props;
  // const [kindergartens, setKindergartens] = useState([]);
  const [confirmedKindergartens, setConfirmedKindergartens] = useState([]);
  const [notConfirmedKindergartens, setNotConfirmedKindergartens] = useState([]);
  const [removedKindergartens, setRemovedKindergartens] = useState([]);
  const [sort, setSort] = useState(
    {
      active: { key: 'created_at', order: 'desc', name: 'Erstellt' },
      all: [
        { key: 'name', order: 'asc', name: 'Name' },
        { key: 'city', order: 'asc', name: 'Stadt' },
        { key: 'street', order: 'asc', name: 'Strasse' },
        { key: 'postcode', order: 'asc', name: 'Postleitzahl' },
        { key: 'confirmed', order: 'asc', name: 'Karte' },
        { key: 'created_at', order: 'asc', name: 'Erstellt' },
        { key: 'products', order: 'desc', name: 'Spende' }
      ]
    }
  );
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [query, setQuery] = useState('/admins/kindergartens');
  const { status, data } = useFetch({
    url: query, method: 'GET', headers: {
      'X-CSRF-Token': authenticityToken,
      'Content-Type': 'application/json'
    }
  });
  const [filter, setFilter] = useState('all');

  useEffect(() => {
    if (!Array.isArray(data)) {
      setConfirmedKindergartens([...data.kindergartens_confirmed]);
      setNotConfirmedKindergartens([...data.kindergartens_not_confirmed]);
    };
  }, [data]);

  useEffect(() => {
    const anchor = window.location.href.split("#")[1];
    if (anchor) {
      const tr = document.getElementById(`admin-table-kindergarten:${anchor}`);
      if (tr) {
        const elementPosition = tr.getBoundingClientRect().top;
        const offsetPosition = elementPosition - 83.5;

        window.history.pushState({}, null, window.location.href.split("#")[0]);

        window.scrollBy({
          top: offsetPosition,
          behavior: 'smooth'
        });
      }
    }
  });

  const sortedKindergartens = (kindergartens) => {
    const { key, order } = sort.active;
    const sortedKindergartens = kindergartens.sort(sorting(key, order));

    return sortedKindergartens;
  }

  const orderKindergartens = () => {
    let kindergartens;
    if (filter === 'all') {
      kindergartens = [...confirmedKindergartens, ...notConfirmedKindergartens];
    } else if (filter === 'confirmed') {
      kindergartens = confirmedKindergartens;
    } else if (filter === 'notConfirmed') {
      kindergartens = notConfirmedKindergartens;
    } else {
      kindergartens = [];
    }

    let searchedKindergartens = searchQuery === '' ? kindergartens : kindergartens.filter(
      kindergarten => kindergarten.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        kindergarten.city.toLowerCase().includes(searchQuery.toLowerCase()) ||
        kindergarten.postcode.toLowerCase().includes(searchQuery.toLowerCase()) ||
        kindergarten.street.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return searchedKindergartens;
  }

  const onTableHeaderClick = (sortItem) => {
    if (sort.active.key === sortItem.key) {
      setSort(
        { ...sort, active: { ...sortItem, order: sort.active.order === 'asc' ? 'desc' : 'asc' } }
      );
    } else {
      setSort({ ...sort, active: sortItem });
    }
  }

  const getKindergartens = () => {
    if (confirmedKindergartens && notConfirmedKindergartens) {
      const orderdKindergartens = orderKindergartens();

      return sortedKindergartens(orderdKindergartens);
    };
    return [];
  }

  const onSearchChange = (e) => {
    const value = e.target.value;

    setSearchQuery(value);
  }

  const onConfirmedToggle = (id, confirmed) => {
    let updatingKindergarten;

    if (confirmed) {
      updatingKindergarten = notConfirmedKindergartens.find(kindergarten => {
        return kindergarten.id === id
      });

      updatingKindergarten.confirmed = confirmed;

      setNotConfirmedKindergartens(
        notConfirmedKindergartens.filter(kindergarten => {
          return kindergarten.id !== id;
        })
      );

      setConfirmedKindergartens([...confirmedKindergartens, updatingKindergarten]);
    }

    if (!confirmed) {
      updatingKindergarten = confirmedKindergartens.find(kindergarten => {
        return kindergarten.id === id
      });

      updatingKindergarten.confirmed = confirmed;

      setConfirmedKindergartens(
        confirmedKindergartens.filter(kindergarten => {
          return kindergarten.id !== id;
        })
      );

      setNotConfirmedKindergartens([...notConfirmedKindergartens, updatingKindergarten]);
    }
  }

  const onDelete = (id) => {
    setRemovedKindergartens([...removedKindergartens, id]);
  }

  const kindergartens = getKindergartens();

  return (
    <div>
      {(status === 'idle' || status === 'fetching' || loading) &&
        <Spinner size="large" isFloating={true} />
      }
      {status === 'fetched' && !loading &&
        <div>
          <div className="admin-table-filters">
            <input
              className="admin-table-filters_search"
              onChange={onSearchChange}
              value={searchQuery}
              placeholder="Suche (Name, Stadt...)"
            />
            <select
              className="admin-table-filters_select"
              onChange={(e) => { setFilter(e.target.value) }}
              value={filter}
            >
              <option value={'all'}>Alle</option>
              <option value={'confirmed'}>Auf der Karte</option>
              <option value={'notConfirmed'}>Nicht auf der Karte</option>
            </select>
            <div className="admin-table-filters_number">
              Angezeigte Einrichtungen: {kindergartens.length}
            </div>
          </div>
          <table>
            <thead>
              <tr>
                {sort.all.map((sortItem, key) => {
                  return (
                    <th
                      className="admin-table-header"
                      key={key}
                      onClick={() => onTableHeaderClick(sortItem)}
                    >
                      <div
                        className={
                          sortItem.key === sort.active.key ?
                            'admin-table-header-item admin-table-header-item_active' : 'admin-table-header-item'
                        }
                      >
                        {sortItem.name}
                        {sortItem.key === sort.active.key &&
                          <FontAwesomeIcon icon={sort.active.order === 'asc' ? faArrowDown : faArrowUp} />
                        }
                      </div>
                    </th>
                  );
                })}
                {/* <th>Spende</th> */}
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {kindergartens.map((kindergarten, key) => {
                if (kindergarten && !removedKindergartens.includes(kindergarten.id)) {
                  return (
                    <AdminKindergartenItem
                      key={key}
                      kindergarten={kindergarten}
                      onConfirmedToggle={onConfirmedToggle}
                      authenticityToken={authenticityToken}
                      onDelete={onDelete}
                      onDonationButtonClick={props.onDonationButtonClick}
                    />
                  );
                }
              })}
            </tbody>
          </table>
        </div>
      }
    </div>
  );
}

export default AdminKindergartens;