import React from 'react';
import Spinner from './Spinner';
import './Button.css';

const Button = ({ text, loading, onClick, additionalStyle, disabled = false }) => {

  const onButtonClick = (e) => {
    if (!disabled) {
      onClick(e);
    }
  }

  return (
    <button className={disabled ? 'custom-button custom-button__disabled' : 'custom-button'} onClick={onButtonClick} style={additionalStyle}>
      {!loading && text}
      {loading &&
        <div style={{ marginLeft: '5px' }}>
          <Spinner size={'button'} isPulse={false} />
        </div>
      }
    </button>
  );
}

export default Button;