import React from 'react';
import './AdminAreaDonationLayover.css';
import closeImg from '../../../../assets/images/close2.png';
import AdminProducts from './AdminProducts';
import InfoLayover from '../InfoLayover';

const AdminAreaDontaionLayover = ({ onClose, authenticityToken, kindergarten }) => {
  const onDonationUpdateClick = (productIds) => {
    fetch('/kindergarten_products', {
      method: 'POST',
      headers: {
        'X-CSRF-Token': authenticityToken,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        kindergarten_products: productIds.map(productId => {
          return { kindergarten_id: kindergarten.id, product_id: productId }
        })
      })
    }).then(res => {
      if (res.status === 200) {
        const newHref = window.location.href.split("#")[0] + `#${kindergarten.id}`;

        window.location.href = newHref;
        window.location.reload();
      };
      if (res.status === 422) {
        res.json().then(jsonRes => {
          console.log(jsonRes);
        });
      };
    });
  };

  return (
    <InfoLayover
      authenticityToken={authenticityToken}
      onClick={onClose}
      isDonationUpdate={true}
      addressPartLink={'hello'}
      baseLink={'world'}
      kindergarten={kindergarten}
      onDonationUpdateClick={onDonationUpdateClick}
    />
  );
};

export default AdminAreaDontaionLayover;