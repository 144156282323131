import React, { useState, useEffect } from 'react';
import closeImg from '../../../assets/images/close2.png';
import guk100 from '../../../assets/images/guk100.png';
import guk50 from '../../../assets/images/guk50.png';
import poster from '../../../assets/images/poster.png';
import guk15 from '../../../assets/images/guk15.png';
import dgs15 from '../../../assets/images/dgs15.png';
import Product from './Product';
import './InfoLayover.css';
import Spinner from './Spinner';
import Button from './Button';
import { useFetch } from '../hooks';

const InfoLayover = ({
  onClick,
  isCheckout,
  isInfo,
  baseLink,
  addressPartLink,
  kindergarten,
  authenticityToken,
  isNewKindergarten,
  isDonationUpdate,
  onDonationUpdateClick
}) => {
  const imgSources = { guk100, guk50, poster, guk15, dgs15 };
  const GAUrlParameter = '&discount=HELPINGHANDS&utm_campaign=helpinghands';

  // const [products, setProducts] = useState({
  //   guk100: {
  //     name: 'guk100',
  //     text: '100er Set GUK',
  //     selected: false,
  //     price: '€250,00',
  //     productId: '37967158280363:1',
  //     info: 'Aufgrund der hohen Nachfrage können Sets aktuell nur vorbestellt werden. Voraussichtliche Lieferung im März 2022.'
  //   }, 
  //   guk50: {
  //     name: 'guk50',
  //     text: '50er Set GUK',
  //     selected: false,
  //     price: '€150,00',
  //     productId: '37967151726763:1',
  //     info: 'Aufgrund der hohen Nachfrage können Sets aktuell nur vorbestellt werden. Voraussichtliche Lieferung im März 2022.'
  //   },
  //   guk15: {
  //     name: 'guk15',
  //     text: '15er Stater-Set GUK',
  //     selected: false,
  //     price: '€45,00',
  //     productId: '38007997890731:1',
  //     info: 'Aufgrund der hohen Nachfrage können Sets aktuell nur vorbestellt werden. Voraussichtliche Lieferung im März 2022.'
  //   },
  //   dgs15: {
  //     name: 'dgs15',
  //     text: '15er Stater-Set LUG',
  //     selected: false,
  //     price: '€45,00',
  //     productId: '38162174574763:1',
  //     info: 'Aufgrund der hohen Nachfrage können Sets aktuell nur vorbestellt werden. Voraussichtliche Lieferung im März 2022.'
  //   },
  //   poster: {
  //     name: 'poster',
  //     text: 'Poster "Gebärden" GUK',
  //     selected: false,
  //     price: '€30,00',
  //     productId: '37927794475179:1'
  //   },
  // });
  // const [link, setLink] = useState(baseLink + products['guk100'].productId + addressPartLink + GAUrlParameter);
  const [link, setLink] = useState(baseLink + addressPartLink + GAUrlParameter);
  const [error, setError] = useState(false);
  const [newKindergarten, setNewKindergarten] = useState({ name: '', street: '', postcode: '', city: '', email: '', url: '', opt_in: false });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const [products, setProducts] = useState([]);
  let status;
  let data;

  if (isCheckout || isDonationUpdate) {
    ({ status, data } = useFetch({
      url: '/products', method: 'GET', headers: {
        'X-CSRF-Token': authenticityToken,
        'Content-Type': 'application/json'
      }
    }));
  }

  useEffect(() => {
    // const products = data.map(product => {return {}})
    console.log('say what?');
    if (isCheckout || isDonationUpdate) {
      setProducts([...data.map(product => { return { ...product, selected: false } })]);
      return () => {
      }
    }
  }, [data])

  const onKindergartenInputChange = (e) => {
    if (error) {
      setError(false);
    }

    setNewKindergarten({ ...newKindergarten, [e.target.name]: e.target.value })
  }

  const onProductClick = (id) => {
    let productsCopy = [...products];

    productsCopy = productsCopy.map(p => {
      if (p.product.id === id) {
        p.selected = !p.selected;
        return p;
      }

      return p;
    });

    setError(false);

    let link = baseLink;

    productsCopy.map(p => {
      if (p.selected) {
        link += link[link.length - 1] === '1' ? `,${p.product.product_id}:1` : p.product.product_id + ':1';
      }
    });

    setProducts(productsCopy);
    setLink(link + addressPartLink + GAUrlParameter);
  }

  const onCheckOutClick = (e) => {
    setLoading(true);

    if (Object.values(products).filter(product => product.selected).length === 0) {
      e.preventDefault();
      setLoading(false);
      setError(true);
    }
  }

  const onCreateKindergartenClick = (e) => {
    e.preventDefault();

    if (Object.keys(newKindergarten).filter(key => (newKindergarten[key] === '' && key !== 'url')).length > 0) {
      setError('Fülle bitte alle Pflichtfelder aus (*)');
      return;
    }

    if (!(newKindergarten.postcode.trim().length === 5) || (!(parseInt(newKindergarten.postcode).toString().length === 5) && !(parseInt(newKindergarten.postcode).toString().length === 4) && newKindergarten.postcode[0] === 0)) {
      setError('Fehlerhafte Postleitzahl');
      return;
    }

    setLoading(true);

    const url = '/kindergartens';

    fetch(url, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': authenticityToken,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(newKindergarten)
    }).then(res => {
      if (res.status === 200) {
        res.json().then(jsonRes => {
          setLoading(false);
          setSuccess(true);
        });
      };
      if (res.status === 422) {
        res.json().then(jsonRes => {
          setError(Object.values(jsonRes)[0]);
          setLoading(false);
        });
      };
    });
  }

  const onDonationUpdateClickEvent = (e) => {
    e.preventDefault();
    setLoading(true);
    const ids = products.filter(p => p.selected).map(p => p.product.id);

    if (ids.length === 0) {
      setLoading(false);
      setError('Wähle bitte mindestens ein Produkt aus.');
      return;
    }

    onDonationUpdateClick(ids);
  };

  if (isInfo) {
    return (
      <div className="infoLayover-container">
        <div className="infoLayover">
          <img className="infoLayover-close" src={closeImg} onClick={onClick} />
          <h2>helping hands</h2>
          Schön, dass du da bist! <br />
          Hier findest du eine Übersicht aller Kitas, die sich über ein Set freuen würden. Mit der Suchfunktion oben rechts kannst du nach Ort, Postleitzahl oder auch direkt nach einem bestimmten Kitanamen suchen. Klicke einfach auf das Kita-Häuschen, das du unterstützen möchtest und wähle aus, was du ihnen schenken möchtest ❤️
          <div className="marker-button" onClick={onClick}>Zur Karte</div>
        </div>
      </div>
    );
  }

  if (isDonationUpdate) {
    return (
      <div className="infoLayover-container">
        <div className="infoLayover">
          <div>
            Wähle die Produkte aus, die "{kindergarten.name}" gespendet wurden.
          </div>
          <img className="infoLayover-close" src={closeImg} onClick={onClick} />
          <div className="infoLayover-products">
            {products.map((p, key) => {
              const { product, urls, selected } = p;
              return (
                <Product
                  product={product}
                  imgSrc={urls[0]}
                  selected={selected}
                  onProductClick={onProductClick}
                  key={key}
                />
              );
            })}
          </div>
          {error && <div className="infoLayover-error">Bitte wähle mindestens ein Produkt aus.</div>}
          <Button text='Einrichtung updaten' onClick={onDonationUpdateClickEvent} loading={loading} />
        </div>
      </div>
    );
  }

  if (isCheckout) {
    return (
      <div className="infoLayover-container">
        <div className="infoLayover">
          <img className="infoLayover-close" src={closeImg} onClick={onClick} />
          {loading && <Spinner size={'medium'} isFloating={true} />}
          <div>
            Die von dir ausgewählte Kita: "{kindergarten.name}" <br />
            Hier kannst du dir aussuchen, was du verschenken möchtest und anschließend über “Check-Out” deinen Einkauf abschließen.
          </div>
          <div className="infoLayover-products">
            {products.map((p, key) => {
              const { product, urls, selected } = p;
              return (
                <Product
                  product={product}
                  imgSrc={urls[0]}
                  selected={selected}
                  onProductClick={onProductClick}
                  key={key}
                />
              );
            })}
          </div>
          {error && <div className="infoLayover-error">Bitte wähle mindestens ein Produkt aus.</div>}
          {loading && <Button text='zum Check-Out' onClick={onCheckOutClick} disabled={true} />}
          {!loading && <a className="marker-button checkout-button" href={link} onClick={onCheckOutClick}>zum Check-Out</a>}
        </div>
      </div>
    );
  }

  if (isNewKindergarten) {
    if (success) {
      return (
        <div className="infoLayover-container">
          <div className="infoLayover">
            <img className="infoLayover-close" src={closeImg} onClick={onClick} />
            <div>
              Die Einrichtung: "{newKindergarten.name}" wurde erfolgreich erstellt. <b /> Wir überprüfen noch einmal die Daten und schalten dann die Einrichtung live. Vielen Dank und viel Erfolg 🍀
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="infoLayover-container">
        <div className="infoLayover">
          <img className="infoLayover-close" src={closeImg} onClick={onClick} />
          <div>
            Du arbeitest in einer Bildungseinrichtung und würdest gerne unsere flipbooks in den Alltag integrieren? Dann bist du hier genau richtig. Melde deine Einrichtung hier an, sodass ihr die Chance bekommt, ein talking hands Set geschenkt zu bekommen.
          </div>
          <form role='form' acceptCharset="UTF-8" action='/kindergarten' method='post'>
            <div className="infoLayover-field">
              <label className="infoLayover-label" htmlFor="name">Name*:</label><br />
              <input className="infoLayover-input" placeholder="HelpingHands" type="text" name="name" id="name" onChange={onKindergartenInputChange} />
            </div>
            {/* <div className="infoLayover-field">
              <label className="infoLayover-label" htmlFor="description">Beschreibung:</label><br />
              <input className="infoLayover-input" placeholder="HelpingHands..." type="text" name="description" id="description" onChange={onKindergartenInputChange}/>
            </div> */}
            <div className="infoLayover-field">
              <label className="infoLayover-label" htmlFor="street">Straße mit Hausnummer*:</label><br />
              <input className="infoLayover-input" placeholder="Senckenberganlage 39" type="text" name="street" id="street" onChange={onKindergartenInputChange} />
            </div>
            <div className="infoLayover-field">
              <label className="infoLayover-label" htmlFor="postcode">PLZ*:</label><br />
              <input className="infoLayover-input" placeholder="60310" type="text" name="postcode" id="postcode" onChange={onKindergartenInputChange} />
            </div>
            <div className="infoLayover-field">
              <label className="infoLayover-label" htmlFor="city">Stadt*:</label><br />
              <input className="infoLayover-input" placeholder="Frankfurt am Main" type="text" name="city" id="city" onChange={onKindergartenInputChange} />
            </div>
            <div className="infoLayover-field">
              <label className="infoLayover-label" htmlFor="email">Email*:</label><br />
              <input className="infoLayover-input" placeholder="hallo@talking-hands.net" type="text" name="email" id="email" onChange={onKindergartenInputChange} />
              <div className="infoLayover-info-text">Wir benötigen deine E-Mail Adresse für die Nachverfolgung deines Pakets. Wir behandeln die Daten vertraulich und werden sie ausschließlich für diesen Zweck verwenden.</div>
            </div>
            <div className="infoLayover-field">
              <label className="infoLayover-label" htmlFor="url">URL:</label><br />
              <input className="infoLayover-input" placeholder="www.talkinghandsflipbooks.com" type="text" name="url" id="url" onChange={onKindergartenInputChange} />
            </div>
            <input type='hidden' name='authenticity_token' value={authenticityToken} />
            {error && <div className="infoLayover-error">{error}</div>}
            <Button text='Erstellen' onClick={onCreateKindergartenClick} loading={loading} />
          </form>
        </div>
      </div>
    );
  }
};

export default InfoLayover;