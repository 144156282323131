const transfromDate = ({ date }) => {
  let day = date.getDate().toString();
  let month = (date.getMonth() + 1).toString();
  const year = date.getFullYear();

  if (day.length === 1) {
    day = `0${day}`;
  }

  if (month.length === 1) {
    month = `0${month}`
  }

  return `${day}.${month}.${year}`;
}

export default transfromDate;