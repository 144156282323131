import React, { useState, useEffect } from 'react';
import Button from '../Button';
import Spinner from '../Spinner';
import AdminProductItem from './AdminProductItem';
import './AdminProducts.css';
import { useFetch } from '../../hooks';


const AdminProducts = (props) => {
  const { authenticityToken } = props; 

  const [products, setProducts] = useState([]);
  const { status, data } = useFetch({
    url: '/products', method: 'GET', headers: {
      'X-CSRF-Token': authenticityToken,
      'Content-Type': 'application/json'
    }
  });

  useEffect(() => {
    setProducts([...data]);
    return () => {      
    }
  }, [data])

  const onNewButtonClick = () => {
    window.open(`products/new`, '_self')
  };

  return (
    <div>
      <Button text="Neues Produkt erstellen" onClick={onNewButtonClick}/>
      {(status === 'idle' || status === 'fetching') &&
        <Spinner size="large" isFloating={true} />
      }
      {status === 'fetched' && 
        <div className="admin-products-wrapper">
          {products.map((product, key) => {
            return (
              <AdminProductItem 
                product={{...product.product, urls: product.urls}} 
                authenticityToken={authenticityToken}
                key={key}
              />
            );
          })}
        </div>
      }
    </div>
  );
}

export default AdminProducts;