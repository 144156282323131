import React, { useRef, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import KindergartenList from './KindergartenList';
import Marker from './Marker';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import distance from '../../helpers/distanceCalc';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import InfoLayover from './InfoLayover'
import './Home.css';
import { useWindowDimensions } from '../hooks';
import { floorWidth } from '../../helpers/screenScale';
import homePng from '../../../assets/images/home-trimmy.png';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_API_KEY

const Home = (props) => {
  const { markers, center } = props;

  const { height, width } = useWindowDimensions();

  const mapContainer = useRef(null);
  const map = useRef(null);

  const [loading, setLoading] = useState(true);
  const [kindergartens, setKindergartens] = useState([]);
  const [showLayover, setShowLayover] = useState(true);
  const [flooredWidth, setFlooredWidth] = useState(floorWidth(width));
  const [showList, setShowList] = useState(flooredWidth > 999);
  const [isCheckout, setIsCheckout] = useState(false);
  const [isInfo, setIsInfo] = useState(true);
  const [isNewKindergarten, setIsNewKindergarten] = useState(false);
  const [baseLink, setBaseLink] = useState('');
  const [selectedKindergarten, setSelectedKindergarten] = useState(undefined);
  const [addressPartLink, setAddressPartLink] = useState('');

  const onItemClick = (kindergarten) => {
    const markers = document.getElementsByClassName('marker-info-container');

    for (let i = 0; i < markers.length; i++) {
      markers[i].remove();
    }

    map.current.flyTo({
      center: [kindergarten.longitude, kindergarten.latitude],
      zoom: 14,
    });

    const features = map.current.queryRenderedFeatures({
      layers: ['points']
    });

    const kindergartenFeature = features.find(feature => {
      return feature.id === kindergarten.id;
    });

    const markerNode = document.createElement('div');

    ReactDOM.render(
      <Marker
        kindergarten={kindergarten}
        baseLink={kindergartenFeature.properties.baseLink}
        addressPartLink={kindergartenFeature.properties.addressPartLink}
        onButtonClick={onMarkerButtonClick}
        onMarkerCloseClick={onMarkerCloseClick}
      />,
      markerNode
    );

    new mapboxgl.Marker(markerNode)
      .setLngLat([kindergarten.longitude, kindergarten.latitude])
      .addTo(map.current);

    if (showList && flooredWidth < 1000) {
      setShowList(false);
    }
  }

  const onLayoverClick = () => {
    setShowLayover(!showLayover);
  }

  const onMarkerButtonClick = (baseLink, addressPartLink, kindergarten) => {
    setBaseLink(baseLink);
    setAddressPartLink(addressPartLink);
    setShowLayover(true);
    setIsCheckout(true);
    setIsInfo(false);
    setSelectedKindergarten(kindergarten)
  }

  useEffect(() => {
    setFlooredWidth(floorWidth(width));
    if (floorWidth(width) > 999) {
      setShowList(true);
    }
    if (floorWidth(width) < 999) {
      setShowList(false);
    }
  }, [width])

  useEffect(() => {
    if (map.current) return; // initialize map only once
    setLoading(true);
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v11?optimize=true',
      center: center ? center : ['10.4515', '51.1657'],
      // layout: {
      //   'text-allow-overlap': true,
      // },
      maxZoom: 16,
      minZoom: 5,
      zoom: center ? 10 : 5.5,
    });

    const customData = {
      'type': 'FeatureCollection',
      'features': markers.map(marker => {
        return {
          'type': 'Feature',
          'id': marker.kindergarten.id,
          'geometry': {
            'type': 'Point',
            'coordinates': [
              marker.lng, marker.lat
            ]
          },
          'properties': {
            'title': marker.kindergarten.name,
            'kindergarten': marker.kindergarten,
            'baseLink': marker.base_link,
            'addressPartLink': marker.address_link
          }
        }
      })
    };

    map.current.on('load', () => {
      // Add an image to use as a custom marker
      map.current.loadImage(
        homePng,
        (error, image) => {
          if (error) throw error;
          map.current.addImage('custom-marker', image);
          map.current.addSource('points', {
            'type': 'geojson',
            'cluster': true,
            'clusterRadius': 50,
            'clusterMaxZoom': 5,
            'data': customData
          });

          const forwardGeocoder = (query) => {
            const matchingFeatures = [];
            for (const feature of customData.features) {
              // Handle queries with different capitalization
              // than the source data by calling toLowerCase().
              if (
                feature.properties.title
                  .toLowerCase()
                  .includes(query.toLowerCase())
              ) {
                // Add a tree emoji as a prefix for custom
                // data results using carmen geojson format:
                // https://github.com/mapbox/carmen/blob/master/carmen-geojson.md
                const kindergarten = feature.properties.kindergarten;
                feature['place_name'] = `🏡 ${feature.properties.title}, ${kindergarten.city} ${kindergarten.postcode}`;
                feature['center'] = feature.geometry.coordinates;
                // feature['place_name'] = `${kindergarten.street}, ${kindergarten.city} ${kindergarten.postcode}, ${kindergarten.country}`;
                // feature['place_type'] = ['park'];
                matchingFeatures.push(feature);
              }
            }
            return matchingFeatures;

          }

          const geocoder = new MapboxGeocoder({
            accessToken: mapboxgl.accessToken,
            mapboxgl: mapboxgl,
            countries: 'de',
            language: 'de',
            localGeocoder: forwardGeocoder
          });

          map.current.addControl(geocoder);

          map.current.addLayer({
            'id': 'clusteredPoints',
            'type': 'symbol',
            'source': 'points',
            'minzoom': 3,
            'maxzoom': 6,
            filter: ["has", "point_count"],
            'layout': {
              'icon-allow-overlap': true,
              'icon-image': 'custom-marker',
              'icon-size': ['+', ['*', ["get", "point_count"], 0.0003], 0.08]
            },
          });

          map.current.addLayer({
            'id': 'circleCount',
            'type': 'symbol',
            'source': 'points',
            'minzoom': 3,
            'maxzoom': 6,
            layout: {
              'text-font': ['Arial Unicode MS Bold'],
              'text-field': ['get', 'point_count'],
              'text-offset': [0, 0.1]
            },
            paint: {
              'text-color': 'black'
            }
          });

          map.current.addLayer({
            'id': 'points',
            'type': 'symbol',
            'source': 'points',
            'sourceLayer': 'points-symbol',
            'minzoom': 6,
            'layout': {
              'icon-allow-overlap': true,
              'icon-image': 'custom-marker',
              'icon-size': 0.1,
            },
            paint: {
              'icon-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 1, .5]
            }
          });

          map.current.addLayer({
            'id': 'names',
            'type': 'symbol',
            'source': 'points',
            'minzoom': 11,
            'layout': {
              'text-allow-overlap': true,
              'text-field': ['get', 'title'],
              'text-font': [
                'Arial Unicode MS Bold'
              ],
              'text-size': 12,
              'text-offset': [0, 1.25],
              'text-anchor': 'top'
            },
            'paint': {
              'text-color': '#303880',
            }
          })
        }
      );
    });

    map.current.on('click', 'points', (e) => {
      const markers = document.getElementsByClassName('marker-info-container');

      for (let i = 0; i < markers.length; i++) {
        markers[i].remove();
      }

      const coordinates = e.features[0].geometry.coordinates.slice();

      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }

      if (map.current.getZoom() < 14) {
        map.current.flyTo({
          center: e.features[0].geometry.coordinates,
          zoom: 14
        });
      }

      const markerNode = document.createElement('div');

      const kindergarten = JSON.parse(e.features[0].properties.kindergarten);
      ReactDOM.render(
        <Marker
          kindergarten={kindergarten}
          baseLink={e.features[0].properties.baseLink}
          addressPartLink={e.features[0].properties.addressPartLink}
          onButtonClick={onMarkerButtonClick}
          onMarkerCloseClick={onMarkerCloseClick}
        />,
        markerNode
      );

      new mapboxgl.Marker(markerNode)
        .setLngLat(coordinates)
        .addTo(map.current);
    });

    map.current.on('mouseenter', 'points', (e) => {
      map.current.getCanvas().style.cursor = 'pointer';

      const kindergarten = JSON.parse(e.features[0].properties.kindergarten);
      onListItemHover(e.features[0]);
      const kindergartenMarker = document.getElementById(`kindergartenlist-item-id:${kindergarten.id}`);

      if (kindergartenMarker) {
        kindergartenMarker.classList.add('kindergartenlist-item__pseudo-hover');
      }


      if (map.current.getZoom() < 11) {
        const coordinates = e.features[0].geometry.coordinates.slice();

        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        const markerNode = document.createElement('div');

        ReactDOM.render(
          <div className="hover-name-info">
            <div className="hover-name-info-text">{kindergarten.name}</div>
          </div>,
          markerNode
        );

        new mapboxgl.Marker(markerNode)
          .setLngLat(coordinates)
          .addTo(map.current);
      }
    });

    map.current.on('mouseleave', 'points', (e) => {
      map.current.getCanvas().style.cursor = '';
      const features = map.current.queryRenderedFeatures({
        layers: ['points']
      });

      features.map(feature => {
        map.current.setFeatureState({
          source: 'points',
          id: feature.id
        }, {
          hover: false
        });
      });

      const markers = document.getElementsByClassName('hover-name-info');
      const listHover = document.getElementsByClassName('kindergartenlist-item__pseudo-hover');

      for (let i = 0; i < markers.length; i++) {
        markers[i].remove();
      }

      if (listHover[0]) {
        listHover[0].classList.remove('kindergartenlist-item__pseudo-hover');
      };
    });

    map.current.on('idle', () => {
      if (map.current.getLayer('points')) {
        const features = map.current.queryRenderedFeatures({
          layers: ['points']
        });

        getVisibleKindergartens(features);
        setLoading(false);
      }
    });

    map.current.on('movestart', () => {
      const zoom = map.current.getZoom();
      if (zoom > 6) {
        setLoading(true);
      }
    })
  });

  const onMarkerCloseClick = () => {
    const popups = document.getElementsByClassName('marker-info-container');

    for (let i = 0; i < popups.length; i++) {
      popups[i].remove();
    }
  }

  const getVisibleKindergartens = (featuresOnMap) => {
    const zoom = map.current.getZoom();

    if (zoom <= 6) {
      setKindergartens(props.kindergartens);
      return;
    }

    const visibleKindergartens = featuresOnMap?.map(feature => {
      return JSON.parse(feature.properties.kindergarten);
    })

    setKindergartens(visibleKindergartens || []);
  }

  const onNewKindergratenClick = () => {
    setShowLayover(true);
    setIsCheckout(false);
    setIsInfo(false);
    setIsNewKindergarten(true);
  }

  const onListItemHover = (item) => {
    // const features = map.current.queryRenderedFeatures({
    //   layers: ['points']
    // });

    // const kindergartenFeature = features.find(feature => {
    //   return feature.id === item.id;
    // });

    map.current.setFeatureState({
      source: 'points',
      id: item.id
    }, {
      hover: true
    });
  }

  const onListItemLeave = (item) => {
    // const features = map.current.queryRenderedFeatures({
    //   layers: ['points']
    // });

    // const kindergartenFeature = features.find(feature => {
    //   return feature.id === item.id;
    // });

    map.current.setFeatureState({
      source: 'points',
      id: item.id
    }, {
      hover: false
    });
  }

  return (
    <div className="home">
      {showLayover &&
        <InfoLayover
          onClick={onLayoverClick}
          isCheckout={isCheckout}
          isInfo={isInfo}
          isNewKindergarten={isNewKindergarten}
          addressPartLink={addressPartLink}
          baseLink={baseLink}
          kindergarten={selectedKindergarten}
          authenticityToken={props.authenticity_token}
        />
      }
      <div ref={mapContainer} className="map-container" />
      {/* <div className="info" onClick={onLayoverClick}>
        Info
      </div> */}
      <KindergartenList
        showList={showList}
        kindergartens={kindergartens}
        allKitas={kindergartens && kindergartens.length === props.kindergartens.length}
        loading={loading}
        onItemClick={onItemClick}
        onIconClick={() => setShowList(!showList)}
        flooredWidth={flooredWidth}
        onItemHover={onListItemHover}
        onItemLeave={onListItemLeave}
        onNewKindergratenClick={onNewKindergratenClick}
      />
    </div>
  );
}

export default Home;