import React, {StyleSheet} from 'react';

const MapPopUp = (props) => {
  const {kindergarten, link} = props;
  return (
    <div className="map__pop-up" id={`pop-up-id:${kindergarten.id}`}>
      {kindergarten.name}
      <a style={styles.buttonLink} href={link} target="blank">Jetzt spenden</a>
    </div>
  );
}

const styles = {
  buttonLink: {
    backgroundColor: 'red',
    padding: '15px 10px',
    borderRadius: '5px'
  }
}

export default MapPopUp;