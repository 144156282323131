import React from 'react';
import './Product.css';
import roundToTwo from '../../helpers/roundToTwo';

const Product = ({ product, onProductClick, imgSrc, selected}) => {
  const {name, text, price, info, id} = product;

  return (
    <div className="product" style={{'alignSelf': info ? 'flex-end' : 'flex-start'}}>
      <img className="product-img" src={imgSrc} alt="Bild"/>
      <div className="product-bottom">
        <div className="product-text">
          <div>
            {name}
          </div>
          <div>
            {`€${roundToTwo(price / 100)}`}
          </div>
          {info && <div className="product-text__info">{info}</div>}
        </div>
        <div 
          onClick={() => onProductClick(id)} 
          className={`product-select-input ${selected ? 'product-select-input-selected' : 'product-select-input-unselected' }`} 
        />
      </div>
    </div>
  );
}

export default Product;