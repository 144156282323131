import React, { useState, useEffect } from 'react';
import './AdminArea.css';

import AdminKindergartens from './AdminKindergartens';
import AdminProducts from './AdminProducts';
import AdminAreaDonationLayover from './AdminAreaDonationLayover';

const AdminArea = (props) => {
  const { active, authenticity_token } = props;
  const [tabs, setTabs] = useState(
    {
      active: active || 'Einrichtungen',
      all: ['Zur Karte', 'Einrichtungen', 'Produkte']
    }
  );
  const [isLayoverPresent, setIsLayoverPresent] = useState(false);
  const [kindergarten, setKindergarten] = useState(null);

  const onTabItemClick = (tab) => {
    if (tab === 'Zur Karte') {
      if (window.confirm(`Admin bereich verlassen?`)) {
        window.open(`/`, '_self');
      };
      return;
    };

    const url = new window.URL(document.location);
    url.searchParams.set('active', tab);
    window.history.replaceState(null, null, url);

    setTabs({ ...tabs, active: tab });
  };

  const onDonationButtonClick = (clickedKindergarten) => {
    setIsLayoverPresent(true);
    setKindergarten(clickedKindergarten);
  };

  return (
    <div className="admin-area">
      {isLayoverPresent &&
        <AdminAreaDonationLayover
          show={isLayoverPresent}
          onClose={() => setIsLayoverPresent(false)}
          authenticityToken={authenticity_token}
          kindergarten={kindergarten}
        />
      }
      {/* <div className="admin-area-header">
        Admin Bereich
      </div> */}
      <div className="tab-bar">
        {tabs.all.map((tab, key) => {
          return (
            <div
              className={tab === tabs.active ? 'tab-item tab-item_active' : 'tab-item'}
              onClick={() => onTabItemClick(tab)}
              key={key}
            >
              {tab}
            </div>
          );
        })}
      </div>
      <div className="admin-content">
        {tabs.active === 'Einrichtungen' && <AdminKindergartens authenticityToken={authenticity_token} onDonationButtonClick={onDonationButtonClick} />}
        {tabs.active === 'Produkte' && <AdminProducts authenticityToken={authenticity_token} />}
      </div>
      {/* {props.kindergartensConfirmed} */}
    </div>
  );
}

export default AdminArea;