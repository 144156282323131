import React from 'react';
import closeImg from '../../../assets/images/close2.png';
import './Marker.css';

const Marker = (props) => {
  const {
    kindergarten,
    baseLink,
    addressPartLink,
    onButtonClick,
    onMarkerCloseClick
  } = props;

  const onHover = (kindergarten) => {
    const kindergartenMarker = document.getElementById(`kindergartenlist-item-id:${kindergarten.id}`);

    if (kindergartenMarker) {
      kindergartenMarker.classList.add('kindergartenlist-item__pseudo-hover');
    }
  }

  const onMouseLeave = (kindergarten) => {
    const kindergartenMarker = document.getElementById(`kindergartenlist-item-id:${kindergarten.id}`);

    if (kindergartenMarker) {
      kindergartenMarker.classList.remove('kindergartenlist-item__pseudo-hover');
    }
  }

  return (
    <div
      className="marker-info-container"
    >
      <div className="marker-info">
        <img className="marker-close" src={closeImg} onClick={onMarkerCloseClick} />
        {kindergarten.url && <a className="marker-url" href={kindergarten.url} target="blank">{kindergarten.name}</a>}
        {!kindergarten.url && <div className="marker-no-url">{kindergarten.name}</div>}
        <div className="marker-text">
          {`${kindergarten.city} | ${kindergarten.street}`}
        </div>
        <div
          className="marker-button"
          onClick={() => onButtonClick(baseLink, addressPartLink, kindergarten)}
        >
          Jetzt schenken
        </div>
      </div>
    </div>
  );
};

export default Marker;