const floorWidth = (width) => {
  return Math.floor(width / 100) * 100;
}

const screenScale = (width) => {
  const flooredWidth = floorWidth(width);

  switch (flooredWidth) {
    case flooredWidth < 576:
      return 'extraSmall';
    case flooredWidth < 768:
      return 'small';
    case flooredWidth < 992:
      return 'medium';
    case flooredWidth < 1200:
      return 'large';
    default:
      return 'small';
  }
}

export {floorWidth, screenScale};

