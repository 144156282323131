import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { faCircle } from '@fortawesome/free-regular-svg-icons';

import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import './AdminKindergartenItem.css'
import Button from '../Button';
import Spinner from '../Spinner';
import transfromDate from '../../../helpers/transformDate';

const AdminKindergartenItem = ({ kindergarten, authenticityToken, onConfirmedToggle, onDelete, onDonationButtonClick }) => {
  const [loading, setLoading] = useState(false);

  const onButtonClick = () => {
    setLoading(true);
    const url = `/kindergartens/${kindergarten.id}`;

    fetch(url, {
      method: 'PUT',
      headers: {
        'X-CSRF-Token': authenticityToken,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ confirmed: !kindergarten.confirmed })
    }).then(res => {
      if (res.status === 200) {
        setLoading(false);
        res.json().then(jsonRes => {
          if (jsonRes.status === 200) {
            onConfirmedToggle(kindergarten.id, !kindergarten.confirmed);
          }
          if (jsonRes.status === 400) {
            window.alert('Etwas ist schief gelaufen.')
          }
        });
      } else {
        setLoading(false);
        window.alert('Etwas ist schief gelaufen.')
      };
    });
  }

  const onDeleteClick = () => {
    const url = `/kindergartens/${kindergarten.id}`;

    if (window.confirm(`Soll die Einrichtung ${kindergarten.name} wirklich gelöscht werden?`)) {
      fetch(url, {
        method: 'DELETE',
        headers: {
          'X-CSRF-Token': authenticityToken,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ confirmed: !kindergarten.confirmed })
      }).then(res => {
        if (res.status === 200) {
          res.json().then(jsonRes => {
            if (jsonRes.status === 201) {
              onDelete(kindergarten.id);
            }
            if (jsonRes.status === 400) {
              window.alert('Etwas ist schief gelaufen.')
            }
          });
        } else {
          window.alert('Etwas ist schief gelaufen.')
        };
      });
    }
  }

  const link = `https://www.google.com/search?q=${kindergarten.name}+${kindergarten.city}+${kindergarten.street}`;
  const date = new Date(kindergarten.created_at);

  return (
    <tr id={`admin-table-kindergarten:${kindergarten.id}`}>
      <td>{kindergarten.name}</td>
      <td>{kindergarten.city}</td>
      <td>{kindergarten.street}</td>
      <td>{kindergarten.postcode}</td>
      <td>
        {loading && <Spinner size={'table'} isPulse={false} />}
        {!loading && <Button
          onClick={onButtonClick}
          text={kindergarten.confirmed ? 'Entfernen' : 'Hinzufügen'}
          additionalStyle={
            {
              'backgroundColor': kindergarten.confirmed ? 'orange' : 'lightgreen',
              'minWidth': '103px',
              'textAlign': 'center',
              'minHeight': '30px'
            }
          }
        />}
      </td>
      <td>{transfromDate({ date })}</td>
      <td>
        <div className="admin-table-donation">
          {kindergarten.products.length > 0 && <FontAwesomeIcon
            className="admin-table-donation-icon"
            icon={faCheckCircle}
            onClick={() => onDonationButtonClick(kindergarten)}
          />}
          {kindergarten.products.length === 0 && <FontAwesomeIcon
            className="admin-table-donation-icon"
            icon={faCircle}
            onClick={() => onDonationButtonClick(kindergarten)}
          />}
        </div>
      </td>
      <td>
        <div className="admin-table-actions">
          <FontAwesomeIcon
            className="admin-table-actions-icon"
            icon={faGoogle}
            onClick={() => window.open(link, '_blank').focus()}
          />
          <FontAwesomeIcon
            className="admin-table-actions-icon"
            icon={faEdit}
            onClick={() => window.open(`kindergartens/${kindergarten.id}/edit`, '_self')}
          />
          <FontAwesomeIcon
            className="admin-table-actions-icon"
            icon={faTrash}
            onClick={() => onDeleteClick()}
          />
        </div>
      </td>
    </tr>
  );
}

export default AdminKindergartenItem;